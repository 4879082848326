import isTokenExpired from "./isTokenExpired";
import ls from "local-storage";
import localStorageKeys from "./localStorageKeys";

const { fetch: originalFetch } = window;
export { originalFetch };
window.fetch = async (...args) => {
    // Token refresh logic should run if this is an authenticated call.
    if ((args[1] as any)?.headers?.Authorization) {
        // Should we refresh.
        const accessToken:any = ls(localStorageKeys.accessToken);
        if (accessToken && isTokenExpired(accessToken)) {
            const baseUri = process.env.REACT_APP_OKTA_AUTH_SERVER_URL;
            var body: any = {
                grant_type: 'refresh_token',
                scope: 'open_id email profile offile_access',
                redirect_uri: `${window.location.protocol}//${window.location.host}/login/callback`,
                refresh_token: ls(localStorageKeys.refreshToken)
            }
            var res:any = await originalFetch(`${baseUri}/v1/token`, {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/x-www-form-urlencoded'
                },
                body: Object.keys(body).map(key => `${key}=${body[key]}`).join('&')
            })
            .catch(error => {throw new Error(`Refresh Token Error: ${error.statusText}`)});

            var userData = await res.json();
            ls(localStorageKeys.idToken, userData.id_token);
            ls(localStorageKeys.accessToken, userData.access_token);
            ls(localStorageKeys.refreshToken, userData.refresh_token);
        }
    }

    return originalFetch(...args);
};